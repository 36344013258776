exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-big-forks-js": () => import("./../../../src/pages/big-forks.js" /* webpackChunkName: "component---src-pages-big-forks-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-construction-js": () => import("./../../../src/pages/construction.js" /* webpackChunkName: "component---src-pages-construction-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-made-js": () => import("./../../../src/pages/custom-made.js" /* webpackChunkName: "component---src-pages-custom-made-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-fork-arms-js": () => import("./../../../src/pages/fork-arms.js" /* webpackChunkName: "component---src-pages-fork-arms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-msinternational-js": () => import("./../../../src/pages/msinternational.js" /* webpackChunkName: "component---src-pages-msinternational-js" */),
  "component---src-pages-standard-js": () => import("./../../../src/pages/standard.js" /* webpackChunkName: "component---src-pages-standard-js" */),
  "component---src-pages-treinamento-js": () => import("./../../../src/pages/treinamento.js" /* webpackChunkName: "component---src-pages-treinamento-js" */)
}

